export const HOME = "/home";
export const SIGN_IN = "/signin";
export const FARMER = "/farmer";
export const LAND = "land";
export const FARM = "farm";
export const DATA = "/data";
export const SUBFORM = "/subForm";
export const CROPS = "/crops";
export const CERTS = "/certs";
export const CERT_VIEW = "/cert";
