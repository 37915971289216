import React, { useState } from 'react';
import _ from 'lodash';

import { Button, List, Modal } from 'antd';
import CertForm from './certForm';


const mustNotBeBlank = (data, name) => {
  data.validation[name] = (data[name] === '' || data[name] === undefined || data[name] === null) ? 'error' : 'success';
};

function landsCert(farmer) {
  const lands = [];

  if (farmer.lands) {
    Object.keys(farmer.lands).forEach(key => {
      const data = farmer.lands[key];
      lands.push({
        key,
        no: data.no,
        title: data.title,
        selected: false,
        area: '',
        description: '',
        validation: {
          area: 'error',
          description: 'error',
        }
      });
    });
  }

  return lands;
}

const CertModal = props => {
  const { farmer, showCreateCert, setShowCreateCert, createCert } = props;
  const [form, setForm] = useState({
    farmerKey: farmer.key,
    name: farmer.title,
    address: farmer.subTitle,
    standard: '',
    from: '',
    to: '',
    lands: landsCert(farmer),
    validation: {
      standard: 'error',
      from: 'error',
      to: 'error'
    }
  });

  const setFormValue = (data) => {
    mustNotBeBlank(data, 'standard');
    mustNotBeBlank(data, 'from');
    mustNotBeBlank(data, 'to');
    if (data.from && data.to) {
      if (!data.to.isAfter(data.from)) {
        data.validation.from = 'error';
        data.validation.to = 'error';
      }
    }
    data.lands.forEach(land => {
      if (land.selected) {
        land.validation.area = (land.area && land.area.length > 0) ? 'success' : 'error';
        land.validation.description = (land.description && land.description.length > 0) ? 'success' : 'error';
      }
    });
    setForm(data);
  };

  const validData = () => {
    let validLands = true;
    form.lands.forEach(land => {
      if (land.selected) {
        validLands =  validLands && (land.validation.area === 'success' && land.validation.description === 'success');
      }
    });

    return form.validation.standard === 'success' &&
      form.validation.from === 'success' &&
      form.validation.to === 'success' &&
      validLands
  };

  const onSave = () => {
    createCert({
      title: `${form.from.format('DD/MM/YYYY')} - ${form.to.format('DD/MM/YYYY')}`,
      farmerKey: form.farmerKey,
      name: form.name,
      address: form.address,
      standard: form.standard,
      from: form.from.format(),
      to: form.to.format(),
      lands: _.filter(form.lands, (l) => l.selected).map(l => {
        return {
          landKey: l.key,
          no: l.no,
          title: l.title,
          selected: l.selected,
          area: l.area,
          description: l.description
        }
      })
    })
  }

  return (
    <Modal
      visible={showCreateCert}
      onCancel={() => setShowCreateCert(false)}
      footer={[
        <Button disabled={!validData()} type="primary" onClick={onSave}>เก็บข้อมูล</Button>,
        <Button onClick={() => setShowCreateCert(false)}>ยกเลิก</Button>
      ]}
      width="90%"
    >
      <CertForm
        form={form}
        setForm={setFormValue}
      />
    </Modal>
  )
};

export default CertModal;
