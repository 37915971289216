import React, { useEffect, useState } from 'react';
import { Avatar, Breadcrumb, Button, Card, Icon, Spin, Tabs } from 'antd';
import Title from '../Layout/title';
import { auth } from '../Auth';
import { fetchFormDefinition } from '../Query';
import AppLayout from '../Layout';
import Page from './page';

const Bar = ({title, goBack}) => (
  <Breadcrumb>
    <Breadcrumb.Item>
      <Button style={{margin: 0, padding: 0}} type="link" onClick={goBack}>
        <Icon type="arrow-left"/>
      </Button>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <Title label={title}/>
    </Breadcrumb.Item>
  </Breadcrumb>
);

const FormView = ({title, formData, formDefinition, goBack, formPartyId}) => {

  const tabs = [];
  let idx = 0;
  formDefinition.pages.forEach(p => {
    tabs.push((
      <Tabs.TabPane tab={`หน้า ${idx + 1}`} key={idx}>
        <Page pageDefinition={p} formData={formData} formPartyId={formPartyId}/>
      </Tabs.TabPane>
    ));
    idx++;
  });

  if (formData.score) {
    const color = formData.score > 50 ? '#87d068' : '#f56a00';
    tabs.push((
      <Tabs.TabPane tab="คะแนน" key={idx++}>
        <Card title={`สรุปผลคะแนน`}>
          <Avatar shape="square" size={128} style={{backgroundColor: color}}>
            {formData.score}
          </Avatar>
        </Card>
      </Tabs.TabPane>
    ));
  }

  return (
    <div>
      <Bar
        title={`${formDefinition.title} : ${title}`}
        goBack={goBack}
      />
      <Tabs>
        {
          tabs
        }
      </Tabs>
    </div>
  );
};

const FormViewPage = (props) => {

  const {match, location, history} = props;
  const formCode = match ? match.params['code'] : null;
  const title = location ? location.state['title'] : null;
  const formData = location ? location.state['data'] : null;
  const formPartyId = location ? location.state['formPartyId'] : null;

  const [loading, setLoading] = useState(false);
  const [formDefinition, setFormDefinition] = useState(null);

  const {getPartyId} = auth();
  const partyId = formPartyId ? formPartyId : getPartyId();



  useEffect(() => {
    setLoading(true);
    fetchFormDefinition(partyId, formCode).then(formDefinition => {
      setFormDefinition(formDefinition);
      setLoading(false);
    });
  }, [formCode, partyId]);

  return (
    <AppLayout>
      {
        loading
          ? <Spin size="large"/>
          : formDefinition !== null && formData !== null
          ? (<FormView
            title={title}
            formDefinition={formDefinition}
            formData={formData}
            formPartyId={formPartyId}
            goBack={() => {
              history.goBack();
            }}
          />)
          : null
      }
    </AppLayout>
  );
};

export {
  FormViewPage
};
