import React from 'react';
import * as PropTypes from 'prop-types';
import { Dropdown, Icon, Layout, Menu } from 'antd';
import { auth } from '../Auth';

const {
  Header
} = Layout;


function AppHeader(props) {
  const {getUserInfo, signOut} = auth();
  const userInfo = getUserInfo();
  const userMenus = () => (
    <Menu
      className="app-header-dropdown"
      onClick={({key}) => {
        if (key === 'logout') {
          signOut();
        }
      }}
    >
      <Menu.Item key="partyName" className="d-block">{`${userInfo.parties && userInfo.parties.length > 0  ? userInfo.parties[0].name : ''}`}</Menu.Item>
      <Menu.Item key="username" className="d-block">{`username: ${userInfo.username}`}</Menu.Item>

      <Menu.Divider className="d-block"/>
      <Menu.Item key="logout" className="d-block">
        <Icon type="logout"/>
        Logout
      </Menu.Item>
    </Menu>
  );

  return <Header className="app-header">
    <div className="app-header-inner bg-warning">
      <div className="header-left" style={{minWidth: '30px'}}>
        <div className="list-unstyled list-inline">
          <div
            className="list-inline-item d-none d-md-inline-block"
            onClick={props.onClick}
          >
            <Icon
              type={props.collapsedNav ? 'menu-unfold' : 'menu-fold'}
              className="list-icon"
            />
          </div>

          <div
            className="list-inline-item d-md-none"
            onClick={props.onClick1}
          >
            <Icon
              type={props.offCanvasMobileNav ? 'menu-unfold' : 'menu-fold'}
              className="list-icon"
            />
          </div>
        </div>
      </div>

      <div className="header-right">
        <div className="list-unstyled list-inline">
          <Dropdown
            className="list-inline-item"
            overlay={userMenus}
            trigger={['click']}
            placement="bottomRight">
            <p><Icon type="user"/> {userInfo.name}</p>
          </Dropdown>

        </div>
      </div>
    </div>
  </Header>;
}

AppHeader.propTypes = {
  onClick: PropTypes.func,
  collapsedNav: PropTypes.bool,
  onClick1: PropTypes.func,
  offCanvasMobileNav: PropTypes.bool,
};

export default AppHeader;
