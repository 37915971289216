import React, { useState } from 'react';
import { Col, Icon, Input, List, Row } from 'antd';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { fetchFarmers } from '../Query';
import { auth } from '../Auth';
import AppLayout from '../Layout';
import Title from '../Layout/title';
import { FARMER_LABEL } from '../../constants/label';

function Filter(props) {
  return <Input
    style={{
      width: '200px',
      marginLeft: '1em',
      marginRight: '1em',
      marginBottom: '1em'
    }}
    addonBefore={<Icon type="search"/>}
    defaultValue=""
    placeholder="ค้นหาตามชื่อ"
    onChange={props.onChange}
  />;
}

Filter.propTypes = {onChange: PropTypes.func};

const FarmerList = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [data, setData] = useState([]);

  const {getPartyId} = auth();
  const partyId = getPartyId();

  React.useEffect(() => {
    setLoading(true);
    fetchFarmers(partyId).then(farmers => {
      setData(farmers);
      setLoading(false);
    });
  }, [partyId]);


  const displayData = data.filter(item => {
    if (!item.title) {
      console.error('no title', item);
    }
    return item.title && item.title.includes(name);
  });

  const renderItem = item => (
    <List.Item actions={[
      <Link to={`/farmer/${item.key}`}>
        <Icon type="zoom-in"/> ดูรายละเอียด
      </Link>
    ]}>
      <List.Item.Meta
        title={item.title}
        description={item.subTitle}
      >
      </List.Item.Meta>
    </List.Item>
  );

  return (
    <>
      <div style={{marginBottom: '16px'}}>
        <Row type="flex">
          <Col xs={24}>
            <Title label={FARMER_LABEL}/>

            <Filter
              onChange={e => {
                const name = e.target.value;
                setName(name);
              }}
            />
          </Col>
        </Row>
      </div>

      <List
        className="list"
        bordered
        loading={loading}
        itemLayout="horizontal"
        style={{backgroundColor: '#FFF'}}
        dataSource={displayData}
        renderItem={renderItem}
      />
    </>
  );
};

const FarmerListPage = () => (
  <AppLayout>
    <FarmerList/>
  </AppLayout>
);

export {
  FarmerListPage
};
