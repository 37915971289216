import React from 'react';
import { Card } from 'antd';
import QuestionValue, {questionBlock} from './question';

const Page = ({idx, pageDefinition, formData, formPartyId}) => {
  let groups = [];
  pageDefinition.groups.forEach(group => {
    groups.push((
      <Card title={group.title}>
        {
          group.questions.map(q => {
            let display = true;
            if (q.showOn) {
              const stmt = q.showOn;
              const arr = stmt.split('=');
              const sId = arr[0];
              const sValues = arr[1].split(',');
              let found = false;
              sValues.forEach(v => {
                if (formData[sId] && formData[sId].value === v) {
                  found = true;
                }
              });
              display = found;
            }
            if (display) {
              return questionBlock(q, formData, formPartyId);
            } else {
              return null;
            }

          })
        }
      </Card>
    ));
  });

  return (
    <>
      <p>{pageDefinition.title}</p>
      <div className="detail-container">
        {groups}
      </div>
    </>
  );
};

export default Page;
