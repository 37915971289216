import React from "react";
import { Icon, List } from "antd";
import { Link } from "react-router-dom";

const Farms = ({ farmer }) => {
  const farms = [];

  if (farmer.farms) {
    Object.keys(farmer.farms).forEach((key) => {
      const data = farmer.farms[key];
      data.key = key;
      farms.push(data);
    });
  }

  const renderItem = (item) => (
    <List.Item
      actions={[
        <Link
          to={{
            pathname: `/farmer/${farmer.key}/farm/${item.key}`,
            state: {
              farmer,
              farm: item,
            },
          }}
        >
          <Icon type="zoom-in" /> ดูรายละเอียด
        </Link>,
      ]}
    >
      <List.Item.Meta
        title={item.title}
        description={item.subTitle}
      ></List.Item.Meta>
    </List.Item>
  );

  return (
    <>
      <h4 style={{ marginTop: "1em" }}>ฟาร์ม</h4>
      <List
        className="list"
        bordered
        itemLayout="horizontal"
        style={{ backgroundColor: "#FFF" }}
        dataSource={farms}
        renderItem={renderItem}
      ></List>
    </>
  );
};

export default Farms;
