import firebase from '../Firebase';
import Moment from 'moment';
import _ from 'lodash';

const cache = {};
const formCache = {};
let certsCache = {};

const fetchFarmers = (partyId, force = false) => {
  if (!force && cache[partyId]) {
    return new Promise(resolve => {
      resolve(cache[partyId]);
    });
  }

  return firebase.farmers(partyId).once('value').then(snapshot => {
    const farmers = [];
    snapshot.forEach(childSnapshot => {
      const data = childSnapshot.val();
      data.key = childSnapshot.key;
      farmers.push(data);
    });
    cache[partyId] = farmers;
    return farmers;
  });
};

const fetchFarmer = (partyId, key) => {
  if (cache[partyId]) {
    return new Promise(resolve => {
      const found = cache[partyId].filter(item => item.key === key);
      if (found) {
        resolve(found[0]);
      } else {
        resolve(null);
      }
    });
  }

  return firebase.farmers(partyId).child(key).once('value').then(snapshot => {
    const data = snapshot.val();
    data.key = key;
    return data;
  });
};

const fetchFormDefinition = (partyId, formCode) => {
  const key = `${partyId}:${formCode}`;
  if (formCache[key]) {
    return new Promise(resolve => {
      resolve(formCache[key]);
    });
  }
  /* fetch custom per party version first */
  return firebase.formDefinitions(partyId).child(formCode).once('value').then(snapshot => {
    if (snapshot.exists()) {
      const definition = snapshot.val();
      formCache[key] = definition;
      return definition;
    } else {
      /* fetch general global version */
      return firebase.globalFormDefinitions().child(formCode).once('value').then(snapshot => {
        const definition = snapshot.val();
        formCache[key] = definition;
        return definition;
      });
    }
  });
};

const fetchCerts = async (partyId) => {
  let certs = [];
  const farmers = await fetchFarmers(partyId);
  if (farmers && farmers.length > 0) {
    farmers.forEach(farmer => {
      if (farmer.certs) {
        Object.keys(farmer.certs).forEach(key => {
          const data = farmer.certs[key];
          data.key = key;
          data.farmer = farmer;
          certs.push(data);
        });
      }
    })
  }
  return certs;
};

const fetchCrops = async (partyId) => {
  const farmers = await fetchFarmers(partyId);
  const crops = [];
  farmers.forEach(farmer => {
    if (farmer.lands) {
      Object.keys(farmer.lands).forEach(landKey => {
        const land = farmer.lands[landKey];
        if (land.crops) {
          Object.keys(land.crops).forEach(cropKey => {
            const crop = land.crops[cropKey];
            if (crop.data && crop.data.type) {
              const cropType = crop.data.type.value;
              const date = Moment(crop.data['date-expect']);
              const qty = crop.data['expect-qty'];
              crops.push({
                key: cropKey,
                cropType,
                date,
                qty,
              });
            }
          });
        }
      });
    }
  });
  console.log(crops);
  return crops;
};

const saveCert = (partyId, farmerId, data) => {
  const ref = firebase.farmerCerts(partyId, farmerId);
  return ref.push(data);
};

const fetchCert = (partyId, farmerId, certId) => {
  if (cache[partyId]) {
    return new Promise(resolve => {
      const found = _(cache[partyId]).find(item => item.key === farmerId);
      if (found) {
        const foundCert = _(found.certs).find(item => item.key === certId);
        if (foundCert) {
          resolve(foundCert)
        } else {
          resolve(null);
        }
      } else {
        resolve(null);
      }
      resolve(cache[partyId]);
    });

  } else {
    const ref = firebase.farmerCerts(partyId, farmerId).child(certId);
    return ref.once('value').then(snapshot => {
      const data = snapshot.val();
      data.key = certId;
      return data;
    });
  }
}

export {
  fetchFarmers,
  fetchFarmer,
  fetchFormDefinition,
  fetchCrops,
  fetchCerts,
  saveCert,
  fetchCert,
};
