import React from 'react';
import firebase from '../Firebase';
import { SIGN_IN } from '../../constants/routes';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../constants';
import qs from 'querystring';

const userInfoKey = 'userInfo';

export const requireAuth = (WrappedComponent) => {
  return (props) => {
    const user = firebase.getCurrentUser();
    return user
      ? <WrappedComponent {...props}/>
      : <Redirect to={{
        pathname: SIGN_IN,
        state: {
          referrer: props.location.pathname,
        }
      }}/>;
  };
};

/**
 * @returns {{isLogin: boolean, signIn: (function(*, *): (*|firebase.auth.UserCredential|string)), signOut:
 *   (function(): *), getUserInfo: getUserInfo}}
 */
export function auth() {
  const isLogin = firebase.getCurrentUser() !== null;

  const signIn = async (username, password) => {
    const response = await axios.post(`${SERVER_URL}/firebase/podd/auth/`,
      qs.stringify({
        username,
        password,
      }), {
        timeout: 5000,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    const userInfo = response.data;
    localStorage.setItem(userInfoKey, JSON.stringify(userInfo));
    return await firebase.doSignInWithCustomToken(userInfo.firebaseToken);
  };

  const signOut = async () => {
    return firebase.doSignOut().then(() => {
      localStorage.removeItem(userInfoKey);
    });
  };

  const getUserInfo = () => {
    const data = localStorage.getItem(userInfoKey);
    if (data) {
      return JSON.parse(data);
    } else {
      return {};
    }
  };

  const getPartyId = () => {
    const userInfo = getUserInfo();
    if (userInfo.parties && userInfo.parties.length > 0) {
      return userInfo.parties[0].id;
    }
    return 0;
  };

  return {isLogin, getUserInfo, signIn, signOut, getPartyId};
}
