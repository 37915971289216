import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';

export default () => (
  <Link to={routes.HOME}>
    <img
      alt="podd organic"
      style={{
        width: '48px',
        margin: '0px',
        auto: true
      }}
      src='/images/podd_organic.png'/>
    <span style={{marginLeft: 10}}>ผ่อดีดี</span>
  </Link>
)

