import React from 'react';
import * as ROUTES from '../../constants/routes';
import { auth } from '../Auth';
import SignInForm from './SignInForm';
import { Col, Layout, Row } from 'antd';

const SignInPage = (props) => {
  const redirectTo = {
    pathname: ROUTES.HOME,
  };
  // if (props.location && props.location.state) {
  //   redirectTo.pathname = props.location.state.referrer;
  // }
  const { history } = props;
  const [isError, setIsError] = React.useState(false);

  const { signIn } = auth();

  const submit = async ({username, password}) => {
    try {
      setIsError(false);
      await signIn(username, password);
      history.replace(redirectTo);

    } catch (err) {
      setIsError(true);
    }
  };
  return (
    <Layout>
      <Row type="flex" justify="space-around" align="middle" style={{height: '70vh'}}>
        <Col md={8} sm={24} style={{backgroundColor: '#FFFFFF', padding: '48px', border: '1px solid #eee'}}>
          <section className="form-v1-container">
            <h3 style={{textAlign: 'center'}}>ผ่อดีดี เกษตรอินทรีย์</h3>
            <p className="lead">Login เพื่อเข้าสู่ระบบ</p>
            {isError
              ? <p style={{
                color: '#ff4d4f'
              }}>รหัสผู้ใช้ หรือ รหัสผ่าน ไม่ถูกต้อง</p>
              : null}
            <SignInForm onSubmit={submit}/>
          </section>
        </Col>
      </Row>
    </Layout>
  );
};


export default SignInPage;
