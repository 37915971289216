import React from 'react';

const Title = ({label}) => (
  <h1 style={{
    fontSize: '1.5em',
    display: 'inline-block',
  }}>{label}</h1>
);

export default Title;

