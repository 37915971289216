import React from "react";
import AppLayout from "../Layout";
import { Breadcrumb, Card, Icon } from "antd";
import { Link } from "react-router-dom";
import Title from "../Layout/title";
import { FARMER_LABEL } from "../../constants/label";
import * as ROUTES from "../../constants/routes";
import Reproductions from "./reproductions";
import Foods from "./foods";
import Supplements from "./supplements";
import Vaccines from "./vaccines";
import Consumptions from "./consumptions";

function Bar({ farmer, farm }) {
  const homeLink = `${ROUTES.HOME}`;
  const farmerLink = `${ROUTES.FARMER}/${farmer.key}`;
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to={homeLink}>
          <Title label={FARMER_LABEL} />
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to={farmerLink}>
          <Title label={farmer.title} />
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Title label={farm.title} />
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

const FarmDetail = (props) => {
  const { farmer, farm } = props.location.state;
  return (
    <>
      <Bar farmer={farmer} farm={farm} />

      <Card
        hoverable
        extra={[
          <Link
            to={{
              pathname: `/data/farm/${farm.key}/`,
              state: {
                data: farm.data,
                title: farm.title,
              },
            }}
          >
            <Icon type="file-text" /> ข้อมูลฟาร์ม
          </Link>,
        ]}
      >
        <Card.Meta title={farm.title} description={farm.subTitle} />
      </Card>

      <Reproductions farm={farm} />

      <Foods farm={farm} />

      <Supplements farm={farm} />

      <Vaccines farm={farm} />

      <Consumptions farm={farm} />
    </>
  );
};

const FarmDetailPage = (props) => (
  <AppLayout>
    <FarmDetail {...props} />
  </AppLayout>
);

export default FarmDetailPage;
