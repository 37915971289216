import React from 'react';
import { Avatar, Icon, List } from 'antd';
import { Link } from 'react-router-dom';

const Audits = ({farmer}) => {
  let audits = [];
  const renderItem = item => {

    let pathname = `/data/${item.formName || 'audit'}/${item.key}/`;
    return (
      <List.Item actions={[
        <Link to={{
          pathname,
          state: {
            data: item.data,
            formPartyId: item.auditPartyId,
            formName: item.formName,
            formRef: item.formRef,
            title: item.subTitle,
          }
        }}>
          <Icon type="zoom-in"/> ดูรายละเอียด
        </Link>
      ]}>
        <List.Item.Meta
          avatar={<Avatar size="large" shape="square">{(item.data ? item.data.score : '-') || '-'}</Avatar>}
          title={`${item.title} โดย ${item.auditUsername} จาก ${item.auditPartyName}`}
          description={`วันที่ตรวจ ${item.subTitle}`}
        >
        </List.Item.Meta>
      </List.Item>
    );
  };

  if (farmer.audits) {
    Object.keys(farmer.audits).forEach(key => {
      const data = farmer.audits[key];
      data.key = key;
      audits.push(data);
    });
  }

  return (
    <>
      <h4 style={{marginTop: '1em'}}>ตรวจภายใน</h4>
      <List
        className="list"
        bordered
        itemLayout="horizontal"
        style={{backgroundColor: '#FFF'}}
        dataSource={audits}
        renderItem={renderItem}
      />
    </>
  );
};

export default Audits;
