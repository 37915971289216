import React, { useEffect, useState } from "react";
import Title from "../Layout/title";
import { Avatar, Breadcrumb, Button, Card, Icon, Layout, Modal } from "antd";
import { Link } from "react-router-dom";
import { FARMER_LABEL } from "../../constants/label";
import PropTypes from "prop-types";
import QRCode from "qrcode-react";
import { auth } from "../Auth";
import Lands from "./lands";
import Audits from "./audits";
import AppLayout from "../Layout";
import Certs from "./certs";
import { fetchFarmer, saveCert } from "../Query";
import CertModal from "./certModal";
import Farms from "./farms";
import FarmAudits from "./farmAudits";

function Bar(props) {
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/home">
          <Title label={FARMER_LABEL} />
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Title label={props.farmer.title} />
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

Bar.propTypes = { farmer: PropTypes.shape({}) };

function showQRCode(qrCodeValue) {
  Modal.info({
    title: "QR Code",
    content: (
      <div>
        <QRCode value={qrCodeValue} />
      </div>
    ),
    onOk() {},
  });
}

function FarmerData({ farmer, partyId, actions }) {
  let avatar = "/public/images/defaultAvatar.jpg";
  if (farmer.data && farmer.data.avatar) {
    avatar = `data:image/png;base64,${farmer.data.avatar}`;
  }
  const phone = farmer.data && farmer.data.phone ? farmer.data.phone : "";
  const qrCodeValue = `/parties/${partyId}/farmers/${farmer.key}`;
  const [collapsed, setCollapsed] = useState(false);

  const desc = (
    <Layout>
      <Layout.Content style={{ backgroundColor: "#FFFFFF" }}>
        <div>ที่อยู่ : {farmer.subTitle}</div>
        <div>เบอร์โทรศัพท์ : {phone}</div>
        {collapsed ? (
          <div style={{ marginTop: 10 }}>
            <Button onClick={() => showQRCode(qrCodeValue)}>QRCode</Button>
          </div>
        ) : null}
      </Layout.Content>
      <Layout.Sider
        width={128}
        breakpoint="lg"
        collapsedWidth={0}
        style={{ backgroundColor: "#FFFFFF" }}
        onCollapse={(c) => setCollapsed(c)}
      >
        <div>
          <QRCode value={qrCodeValue} />
        </div>
      </Layout.Sider>
    </Layout>
  );

  return (
    <>
      <Card key={farmer.key} hoverable extra={actions}>
        <Card.Meta
          avatar={<Avatar size={128} src={avatar} />}
          title={farmer.title}
          description={desc}
        />
      </Card>
    </>
  );
}

const FarmerDetail = ({ farmerKey }) => {
  const { getPartyId } = auth();
  const partyId = getPartyId();
  const [farmer, setFarmer] = useState({});
  const [showCreateCert, setShowCreateCert] = useState(false);

  useEffect(() => {
    fetchFarmer(partyId, farmerKey).then((farmer) => {
      setFarmer(farmer);
    });
  }, [farmerKey, partyId]);

  const actions = [
    <Button
      key={`button-${farmer.key}`}
      type="primary"
      onClick={() => setShowCreateCert(true)}
    >
      บันทึกใบรับรอง
    </Button>,
    <span key={`span-${farmer.key}`} style={{ marginLeft: 10 }} />,
    <Link
      key={`link-${farmer.key}`}
      to={{
        pathname: `/data/farmer/${farmer.key}/`,
        state: {
          data: farmer.data,
          title: farmer.title,
        },
      }}
    >
      <Icon type="file-text" /> ข้อมูลเกษตรกร
    </Link>,
  ];

  const createCert = async (data) => {
    const ref = await saveCert(partyId, farmerKey, data);
    setShowCreateCert(false);
    farmer.certs[ref.key] = data;

    const newFarmer = {
      ...farmer,
      certs: farmer.certs,
    };

    setFarmer(newFarmer);
  };

  return (
    <div>
      <Bar farmer={farmer} />
      <FarmerData farmer={farmer} partyId={partyId} actions={actions} />
      <Lands farmer={farmer} />
      <Farms farmer={farmer} />
      <Audits farmer={farmer} />
      <FarmAudits farmer={farmer}/>
      <Certs farmer={farmer} />
      {farmer.title ? (
        <CertModal
          farmer={farmer}
          showCreateCert={showCreateCert}
          setShowCreateCert={setShowCreateCert}
          createCert={createCert}
        />
      ) : null}
    </div>
  );
};

const FarmerDetailPage = (props) => {
  return (
    <AppLayout>
      <FarmerDetail farmerKey={props.match.params.key} {...props} />
    </AppLayout>
  );
};

export default FarmerDetailPage;
