import React from 'react';
// 3rd
import './styles/antd.less';
import './styles/bootstrap/bootstrap.scss';
// custom
import './styles/layout.scss';
import './styles/theme.scss';
import './styles/ui.scss';
import './styles/vendors.scss';
import './styles/responsive.scss';

import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import SignInPage from './components/SignIn';
import { requireAuth } from './components/Auth';
import { FarmerDetailPage, FarmerListPage, LandDetailPage, FarmDetailPage } from './components/Farmer';
import FormViewPage from './components/Form';
import { CropListPage } from './components/Crop';
import { CertListPage, CertDetailPage } from './components/Cert';

function App() {
  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to={ROUTES.HOME}/>
        <Route exact path={ROUTES.SIGN_IN} component={SignInPage}/>
        <Route exact path={ROUTES.HOME} component={requireAuth(FarmerListPage)}/>
        <Route exact path={`${ROUTES.FARMER}/:key`} component={requireAuth(FarmerDetailPage)}/>
        <Route exact path={`${ROUTES.FARMER}/:key/${ROUTES.LAND}/:landKey`} component={requireAuth(LandDetailPage)}/>
        <Route exact path={`${ROUTES.FARMER}/:key/${ROUTES.FARM}/:farmKey`} component={requireAuth(FarmDetailPage)}/>
        <Route exact path={`${ROUTES.DATA}/:code/:key`} component={requireAuth(FormViewPage)}/>
        <Route exact path={`${ROUTES.SUBFORM}/:code/:key`} component={requireAuth(FormViewPage)}/>
        <Route exact path={`${ROUTES.CROPS}`} component={requireAuth(CropListPage)}/>
        <Route exact path={`${ROUTES.CERTS}`} component={requireAuth(CertListPage)}/>
        <Route exact path={`${ROUTES.CERT_VIEW}/:farmerKey/:certKey`} component={requireAuth((CertDetailPage))}/>
      </Switch>
    </Router>
  );
}

export default App;
