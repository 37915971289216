import React from 'react';
import { Avatar, Checkbox, Icon, List, Radio } from 'antd';
import { Link } from 'react-router-dom';
import Map from '../Map';
import { Marker } from 'react-mapbox-gl';

const Score = ({score, checked}) => {
  if (score && checked) {
    const color = score > 0 ? '#87d068' : '#f56a00';
    const scoreText = score > 0 ? `+${score}` : score;
    return (
      <Avatar
        size="small"
        shape="square"
        style={{backgroundColor: color, color: '#FFFFFF'}}
      >
        {scoreText}
      </Avatar>
    );
  } else {
    return null;
  }
};

export const questionBlock = (q, formData, formPartyId) => {
  return (
      <div className="row">
        <div className="title">
          {q.title}
          {
            q.leadText ? <i><br/>{q.leadText}</i> : null
          }
        </div>
        <div className="value">
          <QuestionValue
              questionDefinition={q}
              formData={formData}
              formPartyId={formPartyId}
          />
        </div>
      </div>
  )
}

const singleChoiceValue = (questionDefinition, formData) => {
  const v = formData[questionDefinition.id];
  const animalType = formData['animal_type'] || 'สัตว์ปีก';
  return questionDefinition.config.choices.map(choice => {
    const checked = v ? v.value === choice.value : false;
    let score = choice.score || null;
    if (score === null) {
      if (choice.scoreMap) {
        score =  choice.scoreMap[animalType];
      }
    }
    return (
      <>
        <Radio checked={checked}>{choice.text || choice.text_th}</Radio>
        {formData.score ? <Score checked={checked} score={score}/> : null}
        <br/>
        { choice.questions
            ? (
                <div style={{marginLeft: 20}}>
                  {
                    choice.questions.map(subQuestion => {
                      return questionBlock(subQuestion, formData)
                    })
                  }
                </div>
            )
            : null
        }
      </>
    );
  });
};

const multipleChoiceValue = (questionDefinition, formData) => {
  const valueMap = {};
  const values = formData[questionDefinition.id];
  if (values && values.forEach) {
    values.forEach(item => {
      valueMap[item.value] = item;
    });
  }

  return questionDefinition.config.choices.map(choice => {
    let checked = false;
    if (valueMap[choice.value]) {
      checked = true;
    }
    const score = choice.score || null;
    return (
      <>
        <Checkbox checked={checked}>{choice.text || choice.text_th}</Checkbox>
        {formData.score ? <Score checked={checked} score={score}/> : null}
        <br/>
      </>
    );
  });
};

const tableValue = (questionDefinition, formData, formPartyId) => {
  const items = formData[questionDefinition.id];
  if (items) {
    const auditForm = questionDefinition.config.formDefinition;
    const renderItem = item => (
      <List.Item actions={[
        <Link to={{
          pathname: `/subform/${auditForm}/${item.title}/`,
          state: {
            data: item.data,
            title: item.title,
            formPartyId,
          }
        }}>
          <Icon type="zoom-in"/> ดูรายละเอียด
        </Link>
      ]}>
        <List.Item.Meta
          title={item.title}
          description={item.subTitle}
        >
        </List.Item.Meta>
      </List.Item>
    );

    return (
      <List
        bordered
        itemLayout="horizontal"
        style={{backgroundColor: '#FFF'}}
        dataSource={items}
        renderItem={renderItem}
      />
    );
  } else {
    return null;
  }
};

const MapValue = (rawData) => {
  if (rawData === undefined || rawData === null) {
    return null;
  }
  const {latitude, longitude} = rawData;
  return (
    <Map
      style="mapbox://styles/keng/cjxjtzdho0ikd1dozaumqpnf1"
      center={[longitude, latitude]}
      containerStyle={{
        height: '40vh',
        width: '40vw'
      }}>
      <Marker coordinates={[longitude, latitude]} anchor="bottom">
        <div className="mapMarkerStyle"/>
      </Marker>
    </Map>
  );
};

const QuestionValue = ({questionDefinition, formData, formPartyId}) => {
  let value = '';
  if (questionDefinition.type === 'text') {
    value = formData[questionDefinition.id];
  }
  const rawData = formData[questionDefinition.id];
  switch (questionDefinition.type) {
    case 'text':
    case 'number':
    case 'hidden':
      value = rawData;
      break;
    case 'date':
      const tmp = new Date(rawData);
      const y = tmp.getFullYear();
      const m = tmp.getMonth();
      const d = tmp.getDate();
      value = `${d}/${m}/${y}`;
      break;
    case 'choice':
      if (questionDefinition.config.multiple) {
        value = multipleChoiceValue(questionDefinition, formData);
      } else {
        value = singleChoiceValue(questionDefinition, formData);
      }
      break;
    case 'map':
      value = MapValue(rawData);
      break;
    case 'gender':
      value = {
        male: 'ชาย',
        female: 'หญิง'
      }[rawData];
      break;
    case 'image':
    case 'signature':
      let thumb = formData[`${questionDefinition.id}_thumb`];
      if (!thumb) {
        thumb = formData[`${questionDefinition.id}`];
      }
      if (thumb) {
        let src = `data:image/gif;base64,${thumb}`;
        if (thumb.indexOf('http') === 0) {
          src = thumb;
        }
        value = (<img src={src} alt={questionDefinition.title}/>);
      } else {
        value = null;
      }
      break;
    case 'table':
      value = tableValue(questionDefinition, formData, formPartyId);
      break;
    default:
      value = 'unsupported';
  }


  return (
    <p>{value}</p>
  );
};

export default QuestionValue;

