import React, { useState } from 'react';
import AppLayout from '../Layout';
import { Calendar, Col, Icon, Input, Row, Spin } from 'antd';
import Title from '../Layout/title';
import { CROPS_LABEL } from '../../constants/label';
import { auth } from '../Auth';
import { fetchCrops } from '../Query';


function Filter(props) {
  return <Input
    style={{
      width: '200px',
      marginLeft: '1em',
      marginRight: '1em',
      marginBottom: '1em'
    }}
    addonBefore={<Icon type="search"/>}
    defaultValue=""
    placeholder="ค้นหาตามชื่อ"
    onChange={props.onChange}
  />;
}

function filterData(data, date, q) {
  return data.filter(item => {
    const found = item.date.isSame(date, 'day');
    if (q) {
      return found && item.cropType.includes(q);
    } else {
      return found;
    }
  });
}

const CropListPage = () => {
  const [q, setq] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const {getPartyId} = auth();
  const partyId = getPartyId();

  React.useEffect(() => {
    setLoading(true);
    fetchCrops(partyId).then(crops => {
      setLoading(false);
      setData(crops);
    });
  }, [partyId]);

  const dateCellRender = (date) => {
    const items = filterData(data, date, q);
    return (
      <ul>
        {
          items.map(item => (
            <li key={item.key}>{item.cropType} {item.qty}</li>
          ))
        }
      </ul>
    );
  };

  return (
    <AppLayout>
      <div style={{marginBottom: '16px'}}>
        <Row type="flex">
          <Col xs={24}>
            <Title label={CROPS_LABEL}/>

            <Filter
              onChange={e => {
                const name = e.target.value;
                setq(name);
              }}
            />
          </Col>
        </Row>
      </div>
      {
        loading
          ? <Spin>Loading...</Spin>
          : <Calendar
            mode="month"
            dateCellRender={dateCellRender}
          />
      }

    </AppLayout>
  );
};

export {
  CropListPage,
};
