import React, { useEffect, useState } from 'react';
import AppLayout from '../Layout';
import { Breadcrumb, Card, Table } from 'antd';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import Title from '../Layout/title';
import { CERTS_LABEL, FARMER_LABEL } from '../../constants/label';
import * as ROUTES from '../../constants/routes';

const columns = [
  {
    title: 'แปลงที่',
    dataIndex: 'no',
    key: 'no',
  },
  {
    title: 'พื้นที่',
    dataIndex: 'area',
    key: 'area',
  },
  {
    title: 'ผลผลิต',
    dataIndex: 'description',
    key: 'description',
  },
];

function Bar({farmer, cert, callFrom}) {
  const homeLink = `${ROUTES.HOME}`;
  const farmerLink = `${ROUTES.FARMER}/${farmer.key}`;
  const certsLink = `${ROUTES.CERTS}`;

  if (callFrom === 'farmer') {
    return <Breadcrumb>
      <Breadcrumb.Item>
        <Link to={homeLink}>
          <Title label={FARMER_LABEL}/>
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to={farmerLink}>
          <Title label={farmer.title}/>
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Title label={cert.title}/>
      </Breadcrumb.Item>
    </Breadcrumb>;
  } else {
    return <Breadcrumb>
      <Breadcrumb.Item>
        <Link to={certsLink}>
          <Title label={CERTS_LABEL}/>
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Title label={cert.title}/>
      </Breadcrumb.Item>
    </Breadcrumb>
  }

}

const CertDetailPage = (props) => {

  const {farmer, cert, callFrom} = props.location.state;

  const from = Moment(cert.from).format('DD/MM/YYYY');
  const to = Moment(cert.to).format('DD/MM/YYYY');
  return (
    <AppLayout>
      <Bar cert={cert} farmer={farmer} callFrom={callFrom}/>
      <Card
        hoverable
      >
        <Card.Meta
          title={`มาตรฐาน ${cert.standard}`}
          description={`พื้นที่ของ ${farmer.title} ที่ตั้ง ${farmer.subTitle} ได้ผ่านการรับรองมาตรฐาน ${cert.standard} ตั้งแต่วันที่ ${from} ถึงวันที่ ${to}`}
        />
        <div style={{marginTop: 30}}/>
        <Table pagination={false} dataSource={cert.lands} columns={columns} />
      </Card>
    </AppLayout>
  )
};

export default CertDetailPage;
