import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyD5PsZwdB_mdQ5Of2K6p7ldODe1jhO4SrQ',
  authDomain: 'podd-d405a.firebaseapp.com',
  databaseURL: 'https://podd-d405a.firebaseio.com',
  projectId: 'podd-d405a',
  storageBucket: 'podd-d405a.appspot.com',
  messagingSenderId: '655788290838',
  appId: '1:655788290838:web:5c53bcdb437f6d46'
};

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    this.auth = firebase.auth();
    this.db = firebase.database();
  }

  doSignInWithCustomToken = async (token) => {
    const user = await this.auth.signInWithCustomToken(token);
    return user;
  };

  doSignOut = () => this.auth.signOut();

  isLogin = () => this.auth.currentUser != null;

  getCurrentUser = () => this.auth.currentUser;

  farmers = (partyId) => {
    return this.db.ref(`/parties/${partyId}/farmers`);
  };

  formDefinitions = (partyId) => {
    return this.db.ref(`/parties/${partyId}/formDefinitions`);
  };

  globalFormDefinitions = () => {
    return this.db.ref('/formDefinitions');
  };

  certs = (partyId) => {
    return this.db.ref(`parties/${partyId}/certs`);
  };

  farmerCerts = (partyId, farmerId) => this.db.ref(`/parties/${partyId}/farmers/${farmerId}/certs`)

}

const instance = new Firebase();
export default instance;
