import React from 'react';
import { Icon, List } from 'antd';
import { Link } from 'react-router-dom';


const Lands = ({farmer}) => {
  const lands = [];

  if (farmer.lands) {
    Object.keys(farmer.lands).forEach(key => {
      const data = farmer.lands[key];
      data.key = key;
      lands.push(data);
    });
  }

  const renderItem = item => (
    <List.Item actions={[
      <Link to={{
        pathname: `/farmer/${farmer.key}/land/${item.key}`,
        state: {
          farmer,
          land: item
        }
      }}>
        <Icon type="zoom-in"/> ดูรายละเอียด
      </Link>
    ]}>
      <List.Item.Meta
        title={item.title}
        description={item.subTitle}
      >
      </List.Item.Meta>
    </List.Item>
  );

  return (
    <>
      <h4 style={{marginTop: '1em'}}>แปลงปลูก</h4>
      <List
        className="list"
        bordered
        itemLayout="horizontal"
        style={{backgroundColor: '#FFF'}}
        dataSource={lands}
        renderItem={renderItem}
      >

      </List>
    </>
  );
};

export default Lands;
