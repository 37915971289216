import React, { useState, useContext } from 'react';
import { Form, Input, PageHeader, DatePicker, Checkbox } from 'antd';
import locale from 'antd/es/date-picker/locale/th_TH';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tableItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const tableStyle = {
  border: '1px solid #eeeeee'
};

const cellStyle = {
  ...tableStyle,
  padding: '10px'
};




const Lands = ({lands, onChange}) => {

  const setLandValue = (propName, value, key) => {
    console.log(propName, value, key);
    onChange(lands.map(land => {
      if (land.key === key) {
        return {
          ...land,
          [propName]: value,
        }
      } else {
        return land;
      }
    }));
  };

  return (
    <>
      <table width="100%" style={tableStyle}>
        <thead>
          <tr>
            <th width="20%" style={cellStyle}>แปลงปลูก</th>
            <th style={cellStyle}>ผ่านการรับรอง</th>
            <th width="100" style={cellStyle}>พื้นที่</th>
            <th width="50%" style={cellStyle}>ผลผลิตที่รับรอง</th>
          </tr>
        </thead>
        <tbody>
        {
          lands.map(land => (
            <tr key={land.key}>
              <td style={cellStyle}>แปลงที่ {land.no} {land.title}</td>
              <td style={cellStyle} align="center">
                <Checkbox checked={land.selected} onChange={(e) => setLandValue('selected', e.target.checked, land.key)}/>
              </td>
              <td style={cellStyle} align="center">
                {
                  land.selected
                  ? (<Form.Item style={{ margin: 0 }} validateStatus={land.validation.area} wrapperCol={tableItemLayout.wrapperCol}>
                      <Input value={land.area} onChange={(e) => setLandValue('area', e.target.value, land.key)}/>
                    </Form.Item>)
                  : '-'
                }
              </td>
              <td style={cellStyle} align="center">
                {
                  land.selected
                    ? (<Form.Item style={{ margin: 0 }} validateStatus={land.validation.description} wrapperCol={tableItemLayout.wrapperCol}>
                        <Input value={land.description} onChange={e => setLandValue('description', e.target.value, land.key)}/>
                      </Form.Item>)
                    : '-'
                }
              </td>
            </tr>
          ))
        }
        </tbody>
      </table>
    </>
  );
};

function CertForm (props) {
  const {form, setForm} = props;
  return (
    <>
      <PageHeader
        title="ออกใบรับรอง"
      >
      <Form {...formItemLayout} >
        <Form.Item label="ชื่อ">
          <Input readOnly={true} value={form.name}/>
        </Form.Item>
        <Form.Item label="ที่อยู่">
          <Input readOnly={true} value={form.address}/>
        </Form.Item>
        <Form.Item label="ผ่านการรับรองมาตรฐาน" required validateStatus={form.validation.standard}>
          <Input
            value={form.standard}
            onChange={(e) => setForm({...form, standard: e.target.value}, 'standard')}/>
        </Form.Item>
        <Form.Item label="ตั้งแต่วันที่" required validateStatus={form.validation.from}>
          <DatePicker
            locale={locale}
            format="DD/MM/YYYY"
            onChange={(d) => setForm({...form, from: d}, 'from')} />
        </Form.Item>
        <Form.Item label="ถึงวันที่" required validateStatus={form.validation.to}>
          <DatePicker
            locale={locale}
            format="DD/MM/YYYY"
            onChange={(d) => setForm({...form, to: d}, 'to')} />
        </Form.Item>
        <Lands
          lands={form.lands}
          onChange={(lands) => setForm({...form, lands})}/>

      </Form>
      </PageHeader>
    </>
  )
}

export default CertForm;
