import React from "react";
import AppLayout from "../Layout";
import { Breadcrumb, Card, Icon } from "antd";
import { Link } from "react-router-dom";
import Title from "../Layout/title";
import { FARMER_LABEL } from "../../constants/label";
import * as ROUTES from "../../constants/routes";
import Crops from "./crops";
import InputFactors from "./inputFactors";
import QRCode from "qrcode-react";
import { auth } from "../Auth";

function Bar({ farmer, land }) {
  const homeLink = `${ROUTES.HOME}`;
  const farmerLink = `${ROUTES.FARMER}/${farmer.key}`;
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to={homeLink}>
          <Title label={FARMER_LABEL} />
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to={farmerLink}>
          <Title label={farmer.title} />
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Title label={land.title} />
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

const LandDetail = (props) => {
  const { getPartyId } = auth();
  const partyId = getPartyId();
  const { farmer, land } = props.location.state;
  const qrCodeValue = `/parties/${partyId}/farmers/${farmer.key}/lands/${land.key}`;
  return (
    <>
      <Bar farmer={farmer} land={land} />

      <Card
        hoverable
        extra={[
          <Link
            to={{
              pathname: `/data/land/${land.key}/`,
              state: {
                data: land.data,
                title: land.title,
              },
            }}
          >
            <Icon type="file-text" /> ข้อมูลแปลงปลูก
          </Link>,
        ]}
      >
        <Card.Meta title={land.title} description={land.subTitle} />

        <div style={{ marginTop: 20 }}>
          <QRCode value={qrCodeValue} />
        </div>
      </Card>

      <Crops land={land} />

      <InputFactors land={land} />
    </>
  );
};

const LandDetailPage = (props) => (
  <AppLayout>
    <LandDetail {...props} />
  </AppLayout>
);

export default LandDetailPage;
