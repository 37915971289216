import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, List } from 'antd';
import Moment from 'moment';

function Certs({farmer}) {
  let certs = [];

  const renderItem = item => {
    const from = Moment(item.from).format('DD/MM/YYYY');
    const to = Moment(item.to).format('DD/MM/YYYY');
    return (
      <List.Item actions={[
        <Link to={{
          pathname: `/cert/${farmer.key}/${item.key}`,
          state: {
            farmer,
            cert: item,
            callFrom: 'farmer'
          }
        }}>
          <Icon type="zoom-in"/> ดูรายละเอียด
        </Link>
      ]}>
        <List.Item.Meta
          title={item.standard}
          description={`${from} - ${to}`}
        >
        </List.Item.Meta>
      </List.Item>
    );
  };

  if (farmer.certs) {
    Object.keys(farmer.certs).forEach(key => {
      const data = farmer.certs[key];
      data.key = key;
      certs.push(data);
    });
  }

  return (
    <>
      <h4 style={{marginTop: '1em'}}>ใบรับรอง</h4>
      <List
        className="list"
        bordered
        itemLayout="horizontal"
        style={{backgroundColor: '#FFF'}}
        dataSource={certs}
        renderItem={renderItem}
      />
    </>
  );
}

export default Certs;
