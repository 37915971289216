import React from 'react';
import { Icon, List } from 'antd';
import { Link } from 'react-router-dom';

const Crops = ({land}) => {
  let crops = [];

  const renderItem = item => (
    <List.Item actions={[
      <Link to={{
        pathname: `/data/crop/${item.key}/`,
        state: {
          data: item.data,
          title: item.title,
        }
      }}>
        <Icon type="zoom-in"/> ดูรายละเอียด
      </Link>
    ]}>
      <List.Item.Meta
        title={item.title}
        description={item.subTitle}
      >
      </List.Item.Meta>
    </List.Item>
  );

  if (land.crops) {
    Object.keys(land.crops).forEach(key => {
      const data = land.crops[key];
      data.key = key;
      crops.push(data);
    });
  }

  return (
    <>
      <h4 style={{marginTop: '1em'}}>พืชที่ปลูก</h4>
      <List
        className="list"
        bordered
        itemLayout="horizontal"
        style={{backgroundColor: '#FFF'}}
        dataSource={crops}
        renderItem={renderItem}
      />
    </>
  );
};

export default Crops;
