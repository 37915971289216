import React from 'react';
import { Icon, List } from 'antd';
import { Link } from 'react-router-dom';

const InputFactors = ({land}) => {
  let inputFactors = [];

  const renderItem = item => (
    <List.Item actions={[
      <Link to={{
        pathname: `/data/inputFactor/${item.key}/`,
        state: {
          data: item.data,
          title: item.title,
        }
      }}>
        <Icon type="zoom-in"/> ดูรายละเอียด
      </Link>
    ]}>
      <List.Item.Meta
        title={item.title}
        description={item.subTitle}
      >
      </List.Item.Meta>
    </List.Item>
  );

  if (land.inputFactors) {
    Object.keys(land.inputFactors).forEach(key => {
      const data = land.inputFactors[key];
      data.key = key;
      inputFactors.push(data);
    });
  }

  return (
    <>
      <h4 style={{marginTop: '1em'}}>ปัจจัยการผลิต</h4>
      <List
        className="list"
        bordered
        itemLayout="horizontal"
        style={{backgroundColor: '#FFF'}}
        dataSource={inputFactors}
        renderItem={renderItem}
      />
    </>
  );

};

export default InputFactors;
