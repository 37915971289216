import React from 'react';
import { Drawer, Icon, Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import * as routes from '../../constants/routes';
import Logo from './logo';
import { CROPS_LABEL, FARMER_LABEL, CERTS_LABEL } from '../../constants/label';

const {
  Sider
} = Layout;

const menus = [
  {key: '1', icon: 'file-text', name: FARMER_LABEL, path: routes.HOME},
  {key: '2', icon: 'calendar', name: CROPS_LABEL, path: routes.CROPS},
  {key: '3', icon: 'safety-certificate', name: CERTS_LABEL, path: routes.CERTS},
];

const menuItems = [];

menus.forEach(it => {
  menuItems.push(
    <Menu.Item key={it.key} className="nav-text">
      <Link to={it.path}>
        <div style={{cursor: 'aointer'}}>
          <Icon type={it.icon}/>
          <span className="nav-text">{it.name}</span>
        </div>
      </Link>
    </Menu.Item>
  );
});

function AppMenu(props) {
  return (
    <Menu
      mode="inline"
      theme="light"
      defaultSelectedKeys={[props.initPathKey]}
    >
      {menuItems}
    </Menu>
  );
}

AppMenu.propTypes = {
  initPathKey: PropTypes.any
};


function SideMenu(props) {
  return <Sider
    collapsible
    collapsed={props.collapsed}
    trigger={null}
    id="app-sidenav"
    className="app-sidenav d-none d-md-flex sidenav-bg-light"
    width={props.sidenavWidth}
    collapsedWidth={0}
  >
    <section className="sidenav-header bg-warning">
      <Logo/>
    </section>
    <div className="sidenav-content">
      <AppMenu initPathKey={props.initPathKey}/>
    </div>
  </Sider>;
}

SideMenu.propTypes = {
  collapsed: PropTypes.bool,
  sidenavWidth: PropTypes.number,
  initPathKey: PropTypes.any
};


function MobileSideMenu({setOffCanvasMobileNav, offCanvasMobileNav, initPathKey, sidenavWidth}) {
  return <Drawer
    closable={false}
    visible={!offCanvasMobileNav}
    placement="left"
    className="d-md-none app-drawer"
    width={sidenavWidth}
    onClose={() => {
      setOffCanvasMobileNav(true);
    }}
  >
    <Sider
      trigger={null}
      id="app-sidenav"
      className="app-sidenav sidenav-bg-light"
      width={sidenavWidth}
    >
      <section className="sidenav-header bg-warning">
        <Logo/>
      </section>
      <div className="sidenav-content">
        <AppMenu initPathKey={initPathKey}/>
      </div>
    </Sider>
  </Drawer>;
}

MobileSideMenu.propTypes = {
  offCanvasMobileNav: PropTypes.bool,
  setOffCanvasMobileNav: PropTypes.func,
  sidenavWidth: PropTypes.number,
  initPathKey: PropTypes.any
};

export {
  menus,
  menuItems,
  AppMenu,
  SideMenu,
  MobileSideMenu,
};
