import React, { useState } from 'react';
import { Layout } from 'antd';
import { menus, MobileSideMenu, SideMenu } from './menu';
import AppHeader from './header';

const {
  Content, Footer
} = Layout;

const sidenavWidth = 200;

function AppLayout(props) {
  const [collapsedNav, setCollapsedNav] = useState(false);
  const [offCanvasMobileNav, setOffCanvasMobileNav] = useState(true);
  // match current path with menu

  const path = props.location ? props.location.pathname : '/';
  const matchMenu = menus.find(it => path.indexOf(it.path) > -1);
  const initPathKey = matchMenu && matchMenu.key;

  const onToggleCollapsedNav = () => {
    setCollapsedNav(!collapsedNav);
  };

  const onToggleOffCanvasMobileNav = () => {
    setOffCanvasMobileNav(!offCanvasMobileNav);
  };

  return (
    <div id="app">
      <div className="app-layout-container">
        <Layout id="app-layout" className="app-layout ant-layout-has-sider">

          <div className="app-sidenav-container">
            <div className="app-sidenav-container">
              <SideMenu
                collapsed={collapsedNav}
                initPathKey={initPathKey}
                sidenavWidth={sidenavWidth}
              />
              <MobileSideMenu
                offCanvasMobileNav={offCanvasMobileNav}
                setOffCanvasMobileNav={setOffCanvasMobileNav}
                initPathKey={initPathKey}
                sidenavWidth={sidenavWidth}
              />
            </div>
          </div>

          <Layout>
            <AppHeader
              onClick={onToggleCollapsedNav}
              collapsedNav={collapsedNav}
              onClick1={onToggleOffCanvasMobileNav}
              offCanvasMobileNav={offCanvasMobileNav}
            />

            <Content id="app-content">
              <div style={{padding: '24px'}}>
                <article className="article">
                  {props.children}
                </article>
              </div>
            </Content>
            <Footer
              style={{textAlign: 'center'}}>
              PODD Farmer App
            </Footer>
          </Layout>
        </Layout>
      </div>
    </div>
  );
}

export default AppLayout;
