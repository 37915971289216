import React from "react";
import { Icon, List } from "antd";
import { Link } from "react-router-dom";

const Vaccines = ({ farm }) => {
  let vaccines = [];

  const renderItem = (item) => (
    <List.Item
      actions={[
        <Link
          to={{
            pathname: `/data/vaccine/${item.key}/`,
            state: {
              data: item.data,
              title: item.title,
            },
          }}
        >
          <Icon type="zoom-in" /> ดูรายละเอียด
        </Link>,
      ]}
    >
      <List.Item.Meta
        title={item.title}
        description={item.subTitle}
      ></List.Item.Meta>
    </List.Item>
  );

  if (farm.vaccines) {
    Object.keys(farm.vaccines).forEach((key) => {
      const data = farm.vaccines[key];
      data.key = key;
      vaccines.push(data);
    });
  }

  return (
    <>
      <h4 style={{ marginTop: "1em" }}>วัคซีน</h4>
      <List
        className="list"
        bordered
        itemLayout="horizontal"
        style={{ backgroundColor: "#FFF" }}
        dataSource={vaccines}
        renderItem={renderItem}
      />
    </>
  );
};

export default Vaccines;
