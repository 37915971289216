import React from 'react';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from '@jbuschke/formik-antd';
import { Icon } from 'antd';

const SignInForm = ({onSubmit}) => {
  return <Formik
    initialValues={{username: '', password: ''}}
    validate={values => {
      const errors = {};
      if (!values.username) {
        errors.username = 'กรุณาระบุ รหัสผู้ใช้';
      }
      if (!values.password) {
        errors.password = 'กรุณาระบุ รหัสผ่าน';
      }
      return errors;
    }}
    onSubmit={(values, {setSubmitting}) => {
      setTimeout(() => {
        setSubmitting(false);
        onSubmit(values);
      }, 400);
    }}
  >
    {({isSubmitting}) => (
      <Form>

        <Form.Item name="username">
          <Input
            prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
            name="username"
            placeholder="รหัสผู้ใช้งาน"
          />
        </Form.Item>

        <Form.Item name="password">
          <Input.Password
            prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
            name="password"
            placeholder="รหัสผ่าน"
          />
        </Form.Item>

        <SubmitButton disabled={isSubmitting}>Login</SubmitButton>
      </Form>
    )}
  </Formik>;
};

export default SignInForm;
