import React, { useState } from 'react';
import AppLayout from '../Layout';
import { Col, Icon, List, Row, Button, Modal } from 'antd';
import Title from '../Layout/title';
import { CERTS_LABEL } from '../../constants/label';
import { fetchCerts } from '../Query';
import { Link } from 'react-router-dom';
import { auth } from '../Auth';
import Moment from 'moment';

const CertList = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const {getPartyId} = auth();
  const partyId = getPartyId();

  React.useEffect(() => {
    setLoading(true);
    fetchCerts(partyId).then(certs => {
      setData(certs);
      setLoading(false);
    })
  }, [partyId]);

  const renderItem = item => {
    const from = Moment(item.from).format('DD/MM/YYYY');
    const to = Moment(item.to).format('DD/MM/YYYY');
    return (
      <List.Item actions={[
        <Link to={{
          pathname: `/cert/${item.farmerKey}/${item.key}`,
          state: {
            farmer: item.farmer,
            cert: item,
            callFrom: 'certs'
          }
        }}>
          <Icon type="zoom-in"/> ดูรายละเอียด
        </Link>
      ]}>
        <List.Item.Meta
          title={item.name}
          description={`${item.standard} ${from} - ${to}`}
        >
        </List.Item.Meta>
      </List.Item>
    );
  };

  return (
    <>
      <div style={{marginBottom: '16px'}}>
        <Row type="flex">
          <Col xs={24}>
            <Title label={CERTS_LABEL}/>
          </Col>
        </Row>
      </div>
      <List
        className="list"
        bordered
        loading={loading}
        itemLayout="horizontal"
        style={{backgroundColor: '#FFF'}}
        dataSource={data}
        renderItem={renderItem}
        />
    </>
  )
};


export default (props) => {
  return (
    <AppLayout>
      <CertList/>
    </AppLayout>
  )
};
